import { useCallback, useEffect, useMemo, useState } from 'react';

import { GeneralModel, prepareTermForReg, useTranslate } from '@cyferd/client-engine';

import { DropdownOption } from '../DropdownOption';
import { useDropdownDimensions } from '@utils';
import { getElementMenuPosition } from '@utils/getElementMenuPosition';
import { useOutsideClick } from '@utils/useOutsideClick';
import { styles } from './styles';
import { GAP } from '@constants';
import { OptionMenuProps } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { Icon } from '../Icon';
import { InputWrapper } from '../InputWrapper';
import { SelectDropdownType } from './types';

export interface SelectDropdownProps {
  id?: string;
  name?: string;
  label?: string;
  options: GeneralModel.JSONSchemaMetadata['optionList'];
  onChange: (value: any) => void;
  value?: string | number;
  description?: string;
  allowEmpty?: boolean;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  testid?: string;
  optionList?: OptionMenuProps['optionList'];
  emptyLabel?: string;
  info?: string;
  type?: SelectDropdownType;
  disabledType?: GeneralModel.DisabledType;
}

export const SelectDropdown = ({
  testid = 'select-dropdown',
  type = SelectDropdownType.REGULAR,
  onChange,
  options,
  disabled,
  emptyLabel = 'Select...',
  disabledType,
  ...props
}: SelectDropdownProps) => {
  const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);
  const { translate } = useTranslate();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [currentList, setCurrentList] = useState<SelectDropdownProps['options']>(options);
  const [searchString, setSearchString] = useState<string>('');
  const hasSelection = useMemo(() => ![null, undefined].includes(props.value), [props.value]);
  const selectedOption: GeneralModel.JSONSchemaMetadata['optionList'][0] = useMemo(
    () =>
      hasSelection
        ? (options?.find(o => o?.value === props.value) ?? { label: String(props.value), value: props.value })
        : { value: null, label: isReadonly ? /* istanbul ignore next */ '' : emptyLabel },
    [emptyLabel, hasSelection, isReadonly, options, props.value]
  );

  const onClickOutside = useCallback(() => setOpen(false), []);

  const outsideRef: React.LegacyRef<HTMLDivElement> = useOutsideClick(onClickOutside);

  const { mainRef, dropdownRef, triggerDimensions, menuDimensions, clientDimensions } = useDropdownDimensions();

  const onSelect = useCallback(
    (v: SelectDropdownProps['value']) => {
      onChange(v);
      setSearchString(null);
      setOpen(false);
    },
    [onChange]
  );

  const onEdit = useCallback(() => {
    /* istanbul ignore else */
    if (!disabled) setOpen(true);
  }, [disabled]);

  const onChangeInput = event => setSearchString(event?.target?.value);

  useEffect(() => {
    if (searchString) {
      const regex = new RegExp(prepareTermForReg(searchString), 'i');
      setCurrentList(options.filter(({ label, value }) => regex.test(translate(label)) || regex.test(String(value))));
    } else {
      setCurrentList(options);
    }
  }, [searchString, options, translate]);

  return (
    <div ref={outsideRef}>
      <div data-testid={`${testid}-container`}>
        <InputWrapper
          description={props.description}
          disabled={!!disabled}
          disabledType={disabledType}
          errorMessage={props.errorMessage}
          id={props.id}
          label={props.label}
          onChange={onChange}
          optionList={props.optionList}
          required={props.required}
          testid={testid}
          value={props.value}
          unlimitedHeight={false}
          info={props.info}
          color={selectedOption?.color as GeneralModel.Color.ThemeColor}
          showPlaceholderLine={true}
          inputContainerRef={mainRef}
        >
          {!isOpen && selectedOption ? (
            <span>
              <div css={styles.inputContainer}>
                <div css={styles.selected}>
                  <PreventClickPropagation containerCss={styles.preventClickContainer}>
                    <div onClick={onEdit} css={styles.dropdownIconContainer}>
                      <div css={styles.dropdownContainer} style={{ paddingRight: !!hasSelection && !!props.allowEmpty ? '0' : GAP.L }}>
                        <DropdownOption
                          title={selectedOption?.label}
                          image={selectedOption?.image}
                          value={selectedOption?.value}
                          disabled={disabled}
                          onClose={!!props.allowEmpty && !!hasSelection ? () => onSelect(null) : null}
                          truncate={true}
                          testid={`${testid}-selected`}
                          disableHover={true}
                        />
                      </div>
                    </div>
                  </PreventClickPropagation>
                </div>
                {(!hasSelection || !props.allowEmpty) && !disabled && (
                  <PreventClickPropagation containerCss={styles.preventClickContainer}>
                    <div css={styles.chevronContainer} onClick={onEdit}>
                      <Icon name="keyboard_arrow_down" />
                    </div>
                  </PreventClickPropagation>
                )}
              </div>
            </span>
          ) : (
            <div css={[styles.inputContainer, type === SelectDropdownType.PAGINATION && styles.paginationInputContainer]}>
              <input
                autoFocus={true}
                css={styles.search}
                id={props.id}
                value={searchString || ''}
                disabled={disabled}
                data-testid={`${testid}-search`}
                onChange={onChangeInput}
                onFocus={onEdit}
              />
              {!disabled && (
                <div css={[styles.chevronContainer, type === SelectDropdownType.PAGINATION && styles.paginationChevronContainer]} onClick={onEdit}>
                  <Icon name="keyboard_arrow_down" />
                </div>
              )}
            </div>
          )}
        </InputWrapper>
        {!!isOpen &&
          (() => {
            const menuElementPosition = getElementMenuPosition({ triggerDimensions, menuDimensions, clientDimensions, verticalPadding: 2 });
            /* istanbul ignore next line */
            const containerStyle = {
              width: triggerDimensions.width,
              top: menuElementPosition.style.top
            };
            return (
              <div data-testid={`${testid}-results`} css={styles.listContainer} ref={dropdownRef} style={containerStyle}>
                {currentList
                  .filter(({ hidden }) => !hidden)
                  .map(({ label, value, description, image, color }, i) => (
                    <div key={`${value}-${label}-${i}`} css={styles.optionContainer}>
                      <DropdownOption
                        type={type}
                        title={label}
                        description={description}
                        image={image}
                        value={value}
                        color={color as GeneralModel.Color.ThemeColor}
                        highlight={value === selectedOption?.value}
                        onClick={onSelect}
                        testid={`${testid}-${value}-item`}
                      />
                    </div>
                  ))}
                {!currentList.length && <DropdownOption title="No results found" testid={`${testid}-empty`} />}
              </div>
            );
          })()}
      </div>
    </div>
  );
};

SelectDropdown.displayName = 'SelectDropdown';
