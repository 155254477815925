import React, { ComponentProps, useCallback, useMemo } from 'react';

import { ApiModel, ErrorBoundary, FormComponentRecord, GeneralModel, mergeTruthy } from '@cyferd/client-engine';

import { getLabel } from '@utils';
import { styles } from './styles';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { BaseForm } from '../../smart/CyForm/components/BaseForm';
import { FileInput, FileInputProps } from '../FileInput';
import { MultiFileInputContext } from './MultiFileInputContext';

export type MultiFileInputProps = { apiQuery?: ApiModel.ApiValue['query'] } & Omit<FileInputProps, 'multiple'>;

export const MultiFileInput = ({ onChange, value, apiQuery, ...props }: MultiFileInputProps) => {
  const fileId = props.id;
  const indexedValues = useMemo(() => value && Array.isArray(value) && [...value].map((item, i) => ({ ...item, index: i })), [value]);
  const innerSchema: GeneralModel.JSONSchema = useMemo(
    () => ({
      type: 'object',
      properties: {
        [fileId]: {
          ...props.schema,
          format: GeneralModel.JSONSchemaFormat.ARRAY,
          label: getLabel(props.displayNamePath),
          items: mergeTruthy(
            { ...props.schema?.items },
            { type: 'object', format: GeneralModel.JSONSchemaFormat.FILE, metadata: { hidden: false, isList: true } }
          ),
          metadata: { ...props.schema?.metadata, disabledType: props.disabledType, disabled: props.disabled, detailGroupId: undefined }
        }
      }
    }),
    [fileId, props.disabled, props.disabledType, props.displayNamePath, props.schema]
  );

  const getComponentRecord: ComponentProps<typeof BaseForm>['getComponentRecord'] = useCallback(
    r => ({ ...r, renderInputWrapper: ({ children }) => <ErrorBoundary>{children}</ErrorBoundary> }) as Partial<FormComponentRecord> as FormComponentRecord,
    []
  );

  const unsuportedSpecialFormatList = useMemo(() => [GeneralModel.JSONSchemaFormat.FILE_LIST], []);

  const innerOnChange = useCallback(v => onChange(v?.[fileId]?.filter?.(Boolean)), [fileId, onChange]);

  const formValue = useMemo(() => ({ [fileId]: value?.filter?.(Boolean) }), [fileId, value]);

  return (
    <ErrorBoundary>
      <div css={styles.container}>
        <MultiFileInputContext.Provider value={{ indexedValues }}>
          <ErrorBoundary>
            <PreventClickPropagation containerCss={styles.dropzoneContainer}>
              <FileInput
                {...props}
                onlyDropzone={true}
                displayNamePath={[' ']}
                multiple={true}
                onChange={v => onChange([...(value || []), ...v])}
                allowedFileTypes={props.schema?.metadata?.allowedFileTypes}
              />
            </PreventClickPropagation>
          </ErrorBoundary>
          <BaseForm
            apiQuery={apiQuery}
            unsuportedSpecialFormatList={unsuportedSpecialFormatList}
            value={formValue}
            schema={innerSchema}
            onChange={innerOnChange}
            onGetFileRequest={props.onGetFileRequest}
            onDownloadFile={props.onDownloadFile}
            disableEvaluation={true}
            disabled={props.disabled}
            wrapDetailGroups={false}
            getComponentRecord={getComponentRecord}
          />
        </MultiFileInputContext.Provider>
      </div>
    </ErrorBoundary>
  );
};
