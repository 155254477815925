import {
  ApiModel,
  CollectionModel,
  GeneralModel,
  SchemaFormContext,
  SchemaFormContextValue,
  isDeepEqual,
  isObject,
  mergeTruthy,
  normalize,
  ofTypeSetData,
  recursiveMap,
  replaceDeep,
  swallowError,
  useFinalizeWhileMounted,
  useUnmountObservable
} from '@cyferd/client-engine';
import { BaseForm } from '../../smart/CyForm/components/BaseForm';
import { FormulaInputRow } from '../Evaluator/resources';
import { ComponentProps, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isValidCollectionId } from '../CollectionLookup/useCollectionLookupFetch';
import { CyWrapperContext } from '../../smart/CyWrapper/CyWrapper';
import { takeUntil, tap } from 'rxjs';
import { Spinner } from '../Spinner';
import { styles } from './styles';
import { ENV } from '@constants';
import { FieldsetContent } from '../Fieldset';
import { SelectDropdown } from '../SelectDropdown';

export interface CollectionDataSetProps {
  id?: string;
  value?: { [key: string]: any };
  disabled?: boolean;
  disabledType?: GeneralModel.DisabledType;
  onChange: (record: ApiModel.ApiRecord) => void;
  collectionId?: GeneralModel.EvaluatorFormula<string>;
  recordId?: GeneralModel.EvaluatorFormula<string>;
  path?: string[];
  collection?: CollectionModel.Collection;
  inputList?: FormulaInputRow[];
  allowFormula?: boolean;
  getComponentRecord?: ComponentProps<typeof BaseForm>['getComponentRecord'];
  /** shows a raw version of the form meant for the flow build */
  alt?: boolean;
}

export const CollectionDataSet = ({
  id,
  value,
  disabled,
  disabledType,
  collectionId,
  recordId,
  path,
  collection,
  inputList,
  allowFormula,
  alt,
  onChange,
  getComponentRecord
}: CollectionDataSetProps) => {
  const { fullValue, useParsers } = useContext<SchemaFormContextValue<ApiModel.ApiRecord>>(SchemaFormContext);
  const { useAction } = useContext(CyWrapperContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const finalize = useFinalizeWhileMounted();
  const onCoreDescribe = useAction('coreDescribe');
  const onDestroy$ = useUnmountObservable();
  const [internalCollection, setInternalCollection] = useState<CollectionModel.Collection>(collection);

  const { parseSchemaProperty } = useParsers({ collectionId, recordId });
  const parsedCollectionId = useMemo(() => {
    const parsed = parseSchemaProperty(collectionId, { fullItem: fullValue, path: path?.join?.('.'), definition: null, value: collectionId });
    return !!parsed && typeof parsed === 'string' ? parsed : undefined;
  }, [collectionId, fullValue, parseSchemaProperty, path]);
  const parsedRecordId = useMemo(() => {
    const parsed = parseSchemaProperty(recordId, { fullItem: fullValue, path: path?.join?.('.'), definition: null, value: recordId });
    return !!parsed && typeof parsed === 'string' ? parsed : undefined;
  }, [recordId, fullValue, parseSchemaProperty, path]);

  useEffect(() => {
    if (!isValidCollectionId(parsedCollectionId) || !isValidCollectionId(parsedRecordId)) return setInternalCollection(collection);
    setLoading(true);
    onCoreDescribe({ query: { cursor: { collectionId: parsedCollectionId, id: parsedRecordId } }, pointer: GeneralModel.IGNORED_POINTER_ID })
      .pipe(
        takeUntil(onDestroy$),
        ofTypeSetData(),
        tap(value => {
          setInternalCollection(normalize.collection(value?.query));
        }),
        swallowError(),
        finalize(() => setLoading(false))
      )
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedCollectionId, parsedRecordId]);

  useEffect(() => {
    /* istanbul ignore next line */
    if (!isDeepEqual(collection, internalCollection)) setInternalCollection(collection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  const content = (
    <div data-testid="collection-data-set" id={id}>
      {!!isLoading && (
        <div css={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
      {!isLoading &&
        isObject(internalCollection?.schema?.properties) &&
        (alt ? (
          <AltContent
            disabled={disabled}
            disabledType={disabledType}
            inputList={inputList}
            allowFormula={allowFormula}
            schema={internalCollection?.schema}
            value={value}
            onChange={onChange}
            getComponentRecord={getComponentRecord}
          />
        ) : (
          <BaseForm
            disabled={disabled}
            disabledType={disabledType}
            inputList={inputList}
            allowFormula={allowFormula}
            schema={internalCollection.schema}
            avoidInitialSync={false}
            value={value}
            delayTime={0}
            wrapDetailGroups={false}
            detailGroupList={internalCollection.detailGroupList}
            onChange={onChange}
            getComponentRecord={getComponentRecord}
          />
        ))}
    </div>
  );

  return content;
};

const AltContent = ({ schema, disabled, inputList, disabledType, allowFormula, value, onChange, getComponentRecord }: ComponentProps<typeof BaseForm>) => {
  const isTruthy = (v: any) => v !== undefined;

  const { usedEntries, unusedEntries } = useMemo(
    () =>
      Object.entries(schema.properties || {}).reduce(
        (total, [key, prop]) => {
          const propKey = (() => {
            /* istanbul ignore next line */
            if (prop.format === GeneralModel.JSONSchemaFormat.ASSOCIATION) return `$$${key}`;
            return key;
          })();
          const isUsed = isTruthy(value?.[propKey]);
          const entry = [propKey, prop] as [string, GeneralModel.JSONSchema];
          return {
            usedEntries: [...total.usedEntries, isUsed && entry].filter(Boolean),
            unusedEntries: [...total.unusedEntries, !isUsed && entry].filter(Boolean)
          };
        },
        { usedEntries: [], unusedEntries: [] } as { usedEntries: [string, GeneralModel.JSONSchema][]; unusedEntries: [string, GeneralModel.JSONSchema][] }
      ),
    [schema.properties, value]
  );
  return (
    <FieldsetContent maxColumns={1}>
      {[...usedEntries]
        .sort((a, b) => String(a[1].label).localeCompare(b[1].label))
        .map(([key, prop]) => (
          <div key={key} css={styles.altRow}>
            <div css={styles.altRowTitle}>{prop.label}</div>
            <div>
              <AltPropForm
                propKey={key}
                disabled={disabled}
                disabledType={disabledType}
                inputList={inputList}
                allowFormula={allowFormula}
                schema={prop}
                value={value}
                onChange={onChange}
                getComponentRecord={getComponentRecord}
              />
            </div>
          </div>
        ))}
      {!!unusedEntries.length && (
        <div css={styles.altRow}>
          <SelectDropdown
            testid="field-selector"
            label="Add a field"
            options={unusedEntries.map(([key, prop]) => ({ value: key, label: String(prop.label) })).sort((a, b) => a.label.localeCompare(b.label))}
            onChange={event => !!event && onChange({ ...(value || {}), [event]: null })}
          />
        </div>
      )}
    </FieldsetContent>
  );
};

const AltPropForm = ({
  propKey,
  disabled,
  disabledType,
  inputList,
  allowFormula,
  schema,
  value,
  onChange,
  getComponentRecord
}: ComponentProps<typeof BaseForm> & { propKey: string }) => {
  const rootKey = 'root';

  const mappedValue = useMemo(() => ({ [rootKey]: value?.[propKey] }), [propKey, value]);
  const onInternalChange: ComponentProps<typeof BaseForm>['onChange'] = useCallback(
    event => onChange(replaceDeep(value, event?.[rootKey], propKey)),
    [onChange, propKey, value]
  );

  /** removing all calcs */
  const internalSchema: ComponentProps<typeof BaseForm>['schema'] = useMemo(
    () => ({
      type: 'object',
      properties: {
        [rootKey]: {
          ...recursiveMap(schema, (item, path) =>
            path[path.length - 1] === 'metadata' ? mergeTruthy(item, { calculation: null, hidden: false, disabled: false }, arg => arg !== undefined) : item
          ),
          label: ' ',
          title: ' '
        }
      }
    }),
    [schema]
  );

  return (
    <BaseForm
      disabled={disabled}
      disabledType={disabledType}
      inputList={inputList}
      allowFormula={allowFormula}
      schema={internalSchema}
      avoidInitialSync={true}
      value={mappedValue}
      delayTime={ENV.INPUT_DEBOUNCE_TIME}
      wrapDetailGroups={false}
      onChange={onInternalChange}
      getComponentRecord={getComponentRecord}
      getOptionMenu={event =>
        [
          isDeepEqual(event.path, [rootKey]) &&
            ({ important: true, testid: 'delete-field', tooltip: 'Delete', image: 'delete', color: 'RD_4', onClick: () => onInternalChange(undefined) } as any)
        ].filter(Boolean)
      }
    />
  );
};
