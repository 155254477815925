import { PopoverTrigger } from '@components/elements/Popover';
import { styles } from './styles';
import { ErrorBoundary, GeneralModel, noop } from '@cyferd/client-engine';
import { EvaluatorTree } from '../EvaluatorTree';
import { ComponentProps, useCallback } from 'react';
import { Input } from '@components/elements/Input';
import { MAX_HEIGHT } from '@components/elements/Popover/styles';
import { DragDropContext } from '@hello-pangea/dnd';
import { FormulaInputRow } from '../resources';
import { InputWrapper } from '@components/elements/InputWrapper';
import { InlineFormula } from '../InlineFormula';
import { Icon } from '@components/elements/Icon';
import { COLOR, getDataTypeIcon } from '@constants';

export interface FormulaPreviewProps extends ComponentProps<typeof Input> {
  formula: any;
  inputList?: FormulaInputRow[];
  format?: GeneralModel.JSONSchemaFormat;
  readOnly?: boolean;
}

export const FormulaPreview = ({ inputList, formula, onChange, format, ...props }: FormulaPreviewProps) => {
  const onClear = useCallback(() => onChange?.(undefined), [onChange]);
  if ([null, undefined].includes(formula)) return <Input {...props} onChange={noop} />;

  return (
    <InputWrapper
      {...props}
      onClear={formula !== undefined && !props.disabled && onClear}
      value={formula}
      disabled={props.disabled}
      disabledType={props.disabledType}
    >
      <FormulaPreviewContent formula={formula} inputList={inputList} format={format} avoidOpenOnClick={true} onFocus={props.onFocus} />
    </InputWrapper>
  );
};

export const FormulaPreviewContent = ({
  formula,
  inputList,
  format,
  avoidOpenOnClick,
  onFocus
}: Pick<FormulaPreviewProps, 'formula' | 'inputList' | 'format' | 'onFocus'> & { avoidOpenOnClick: boolean }) => {
  return (
    <PopoverTrigger
      avoidOpenOnClick={avoidOpenOnClick}
      value={
        <div css={styles.popoverContainer}>
          <div css={styles.popoverContent}>
            <DragDropContext onDragEnd={noop}>
              <EvaluatorTree
                readonly={true}
                value={formula}
                inputList={inputList}
                activePath={null}
                pathBeingDragged={null}
                height={MAX_HEIGHT}
                onChange={noop}
                onChangeActive={noop}
                onKeyChange={noop}
              />
            </DragDropContext>
          </div>
        </div>
      }
      containerCss={styles.content}
      contentCss={styles.content}
    >
      <div css={styles.inlineFormula} data-testid="evaluator-input" onClick={() => onFocus?.()}>
        <ErrorBoundary>
          {!!format && <Icon fill={COLOR.NEUTRAL_2} name={getDataTypeIcon(format)} />}
          <InlineFormula value={formula} inputList={inputList} maxElements={10} maxDepth={3} singleLine={true} />
        </ErrorBoundary>
      </div>
    </PopoverTrigger>
  );
};
