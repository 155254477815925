import { useCallback } from 'react';
import { Observable } from 'rxjs';
import { ApiModel, BaseFieldProps, ErrorBoundary, FormComponentRecord, GeneralModel, SchemaFormBaseProps, useLogger, ViewModel } from '@cyferd/client-engine';
import { APP_LOGGER_HEADER } from '@constants';
import { getLabel } from '@utils';
import { CollectionFilter } from '@components/elements/CollectionFilter';
import { CollectionField } from '@components/elements/CollectionField';
import { RichTextEditor } from '@components/elements/RichTextEditor';
import { FormulaInputRow, shouldAllowFormula } from '@components/elements/Evaluator/resources';
import { MenuOption } from '@components/elements/OptionMenu';
import { FileInput } from '@components/elements/FileInput';
import { MultiFileInput } from '@components/elements/MultiFileInput';
import { MultiOptionList } from '@components/elements/MultiOptionList';
import { SyntaxEditor } from '@components/elements/SyntaxEditor';
import { AddressInput } from '@components/elements/AddressInput';
import { JSONSyntaxEditor } from '@components/elements/JSONSyntaxEditor';
import { RatingInput } from '@components/elements/RatingInput';
import { SliderInput } from '@components/elements/SliderInput';
import { CollectionLookup } from '@components/elements/CollectionLookup';
import { PhoneInput } from '@components/elements/PhoneInput';
import { AssociationInput } from '@components/elements/AssociationInput';
import { IconImagePicker } from '@components/elements/IconImagePicker';
import { ColorInput } from '@components/elements/ColorInput';
import { SearchInput } from '@components/elements/SearchInput';
import { EvaluatorWrapper } from '@components/elements/Evaluator/EvaluatorWrapper';
import { MultiOptionDropdown } from '@components/elements/MultiOptionDropdown';
import { CollectionDataSet } from '@components/elements/CollectionDataSet';
import { InfoBlock } from '@components/elements/InfoBlock';
import { ActionDropdown } from '@components/elements/ActionDropdown';
import { CollectionLite } from '@components/elements/CollectionLite';

export const useRenderDefault = ({
  getIds,
  apiQuery,
  allowFormula,
  inputList: originalInputList,
  onGetFileRequest,
  onDownloadFile,
  getOptionMenu,
  getComponentRecord
}: {
  getIds: Function;
  apiQuery: ApiModel.ApiValue['query'];
  allowFormula: boolean;
  inputList: FormulaInputRow[];
  onGetFileRequest: (file: File) => Observable<any>;
  onDownloadFile: ViewModel.TriggerCallback<string>;
  getOptionMenu: (event: BaseFieldProps) => MenuOption[];
  getComponentRecord: (base: FormComponentRecord) => FormComponentRecord;
}): SchemaFormBaseProps['componentRecord']['renderDefault'] => {
  const logger = useLogger(APP_LOGGER_HEADER);

  return useCallback(
    props => {
      const { format, ...rest } = props;

      const inputList = [...(props.schema?.metadata?.inputList || []), ...(originalInputList || [])];
      if (format === GeneralModel.JSONSchemaFormat.EVALUATION) {
        return (
          <ErrorBoundary>
            <div>
              <EvaluatorWrapper
                allowFormula={true}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                fixToFormula={true}
                label={getLabel(props.displayNamePath)}
                inputList={inputList}
                description={props.description}
                required={rest.required}
                format={format}
                openModalOnFocus={props.schema?.metadata?.openModalOnFocus}
                info={rest.schema?.info}
                color={rest.color}
                disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                optionList={getOptionMenu(props)}
              >
                {/* istanbul ignore next | this will never get executed with this settings */ () => null}
              </EvaluatorWrapper>
            </div>
          </ErrorBoundary>
        );
      }

      return (
        <ErrorBoundary>
          <EvaluatorWrapper
            allowFormula={shouldAllowFormula(allowFormula, props.schema.metadata?.allowFormula)}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            label={getLabel(props.displayNamePath)}
            inputList={inputList}
            required={rest.required}
            format={format}
            openModalOnFocus={props.schema?.metadata?.openModalOnFocus}
            info={rest.schema?.info}
            description={rest.description}
            color={rest.color}
            optionList={getOptionMenu(props)}
            disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
          >
            {fixedProps => {
              switch (format) {
                case GeneralModel.JSONSchemaFormat.ADDRESS:
                  return (
                    <AddressInput
                      {...rest}
                      label={getLabel(rest.displayNamePath)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      errorMessage={rest.error}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.FILE:
                  return (
                    <FileInput
                      format={format}
                      {...rest}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      disabled={rest.disabled}
                      onGetFileRequest={onGetFileRequest}
                      onDownloadFile={onDownloadFile}
                      allowedFileTypes={rest.schema.metadata?.allowedFileTypes}
                      info={rest.schema?.info}
                      errorMessage={rest.error}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.FILE_LIST:
                  return (
                    <MultiFileInput
                      format={format}
                      {...rest}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      description={null}
                      disabled={rest.disabled}
                      onGetFileRequest={onGetFileRequest}
                      onDownloadFile={onDownloadFile}
                      apiQuery={apiQuery}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST:
                  return (
                    <MultiOptionList
                      {...(rest as any)}
                      value={fixedProps.value}
                      optionList={rest.getOptionList(rest.schema.items)}
                      menuOptionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      disabled={rest.disabled}
                      label={getLabel(rest.displayNamePath)}
                      errorMessage={rest.error}
                      info={rest.schema?.info}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT:
                  return (
                    <MultiOptionDropdown
                      {...(rest as any)}
                      testid={rest.id}
                      value={fixedProps.value}
                      optionList={rest.getOptionList(rest.schema.items)}
                      menuOptionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      disabled={rest.disabled}
                      label={getLabel(rest.displayNamePath)}
                      errorMessage={rest.error}
                      info={rest.schema?.info}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.SQL:
                case GeneralModel.JSONSchemaFormat.GRAPHQL:
                case GeneralModel.JSONSchemaFormat.XML:
                case GeneralModel.JSONSchemaFormat.YAML:
                  return (
                    <SyntaxEditor
                      {...(rest as any)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      language={format}
                      allowFileInput={rest.schema.metadata?.allowFileInput}
                      errorMessage={rest.error}
                      testid={rest.id}
                      expanded={rest.schema.metadata?.expanded}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.JSON:
                  return (
                    <JSONSyntaxEditor
                      {...(rest as any)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      language={format}
                      allowFileInput={rest.schema.metadata?.allowFileInput}
                      errorMessage={rest.error}
                      testid={rest.id}
                      expanded={rest.schema.metadata?.expanded}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.RATING:
                  return (
                    <RatingInput
                      {...rest}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      roundBy={rest.schema.multipleOf}
                      label={getLabel(rest.displayNamePath)}
                      icon={rest.icon}
                      secondaryColor={rest.secondaryColor}
                      color={rest.color}
                      length={rest.schema.maximum}
                      errorMessage={rest.error}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.RANGE:
                  return (
                    <SliderInput
                      {...rest}
                      {...getIds(rest.id)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      disabled={rest.disabled}
                      uniqueItems={rest.schema.uniqueItems}
                      subtype={rest.schema.metadata?.subtype}
                      multipleOf={rest.schema.multipleOf}
                      maximum={rest.schema.maximum}
                      minimum={rest.schema.minimum}
                      mask={rest.schema.metadata?.mask}
                      errorMessage={rest.error}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.NUMERIC_RANGE:
                  return (
                    <SliderInput
                      {...rest}
                      {...getIds(rest.id)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      disabled={rest.disabled}
                      uniqueItems={rest.schema.uniqueItems}
                      subtype={rest.schema.metadata?.subtype}
                      multipleOf={rest.schema.multipleOf}
                      maximum={rest.schema.maximum}
                      minimum={rest.schema.minimum}
                      mask={rest.schema.metadata?.mask}
                      errorMessage={rest.error}
                      range={true}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.PHONE_NUMBER:
                  return (
                    <PhoneInput
                      {...rest}
                      {...getIds(rest.id)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      disabled={rest.disabled}
                      description={rest.schema.description}
                      errorMessage={rest.error}
                      preferredOptionList={rest.schema?.metadata?.preferredOptionList || []}
                      info={rest.schema?.info}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP:
                  return (
                    <CollectionLookup
                      {...rest}
                      {...getIds(rest.id)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      disabled={rest.disabled}
                      description={rest.schema.description}
                      errorMessage={rest.error}
                      collectionId={rest.schema?.metadata?.collectionId}
                      fetchCriteria={rest.schema?.metadata?.fetchCriteria}
                      fixedItems={rest.schema?.metadata?.optionList}
                      disableFreeText={rest.schema?.metadata?.disableFreeText}
                      info={rest.schema?.info}
                      path={rest.path}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.COLLECTION_FIELD:
                  return (
                    <CollectionField
                      {...rest}
                      {...getIds(rest.id)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      disabled={rest.disabled}
                      description={rest.schema.description}
                      errorMessage={rest.error}
                      collectionId={rest.schema?.metadata?.collectionId}
                      collection={props.schema?.metadata?.collection}
                      condition={rest.schema?.metadata?.condition}
                      info={rest.schema?.info}
                      path={rest.path}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.COLLECTION_FILTER:
                  return (
                    <CollectionFilter
                      {...rest}
                      {...getIds(rest.id)}
                      inputList={inputList}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      disabled={rest.disabled}
                      description={rest.schema?.description}
                      errorMessage={rest.error}
                      collectionId={rest.schema?.metadata?.collectionId}
                      testid={rest.id}
                      entity={rest.schema?.metadata?.entity}
                      info={rest.schema?.info}
                      path={rest.path}
                      allowFormula={rest.schema?.metadata?.allowFormula}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                      expanded={rest.schema.metadata?.expanded}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.COLLECTION_LITE:
                  return (
                    <CollectionLite
                      inputList={inputList}
                      id={rest.id}
                      testid={rest.id}
                      description={rest.description}
                      disabled={rest.disabled}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(rest)]}
                      onChange={fixedProps.onChange}
                      errorMessage={rest.error}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.ASSOCIATION:
                  return (
                    <AssociationInput
                      {...getIds(rest.id)}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      disabled={rest.disabled}
                      description={rest.schema.description}
                      schema={rest.schema}
                      subtype={rest.schema.metadata?.subtype}
                      value={rest.value}
                      path={rest.path}
                      apiQuery={apiQuery}
                      errorMessage={rest.error}
                      disableLink={rest.schema.metadata?.disableLink}
                      disableUnlink={rest.schema.metadata?.disableUnlink}
                      disableCreateNew={rest.schema.metadata?.disableCreateNew}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST:
                  return (
                    <ActionDropdown
                      {...getIds(rest.id)}
                      name={getLabel(rest.displayNamePath)}
                      label={getLabel(rest.displayNamePath)}
                      subtype={rest.schema.metadata?.subtype}
                      description={rest.description}
                      required={rest.required}
                      allowEmpty={!rest.required}
                      disabled={rest.disabled}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      errorMessage={rest.error}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.ICON:
                case GeneralModel.JSONSchemaFormat.ICON_IMAGE:
                  return (
                    <IconImagePicker
                      {...rest}
                      {...getIds(rest.id)}
                      expanded={rest.schema.metadata?.expanded}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      required={rest.required}
                      disabled={rest.disabled}
                      description={rest.schema.description}
                      iconOnly={format === GeneralModel.JSONSchemaFormat.ICON}
                      info={rest.schema?.info}
                      errorMessage={rest.error}
                      color={rest.color}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.COLOR:
                  return (
                    <ColorInput
                      {...getIds(rest.id)}
                      value={fixedProps.value}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      onChange={fixedProps.onChange}
                      label={getLabel(rest.displayNamePath)}
                      disabled={rest.disabled}
                      errorMessage={rest.error}
                      description={rest.description}
                      required={rest.required}
                      info={rest.schema?.info}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.COLLECTION_RECORD:
                  return (
                    <CollectionDataSet
                      id={rest.id}
                      path={rest.path}
                      disabled={props.disabled}
                      inputList={inputList}
                      allowFormula={shouldAllowFormula(allowFormula, props.schema.metadata?.allowFormula)}
                      collection={props.schema?.metadata?.collection}
                      collectionId={props.schema?.metadata?.collectionId}
                      alt={props.schema?.metadata?.alt}
                      recordId={props.schema?.metadata?.recordId}
                      value={fixedProps.value}
                      onChange={fixedProps.onChange}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                      getComponentRecord={getComponentRecord}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.RICH_TEXT:
                  return (
                    <RichTextEditor
                      id={rest.id}
                      description={rest.description}
                      disabled={props.disabled}
                      label={getLabel(rest.displayNamePath)}
                      onChange={fixedProps.onChange}
                      errorMessage={rest.error}
                      optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                      required={rest.required}
                      value={fixedProps.value}
                      info={rest.schema?.info}
                      color={rest.color}
                      unlimitedHeight={rest.schema?.metadata?.unlimitedHeight}
                      disabledType={rest.disabledType || rest.schema?.metadata?.disabledType}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.INFO_BLOCK:
                  return (
                    <InfoBlock
                      id={rest.id}
                      path={rest.path}
                      fixedValue={rest.schema.metadata?.fixedValue}
                      schema={rest.schema}
                      color={rest.color}
                      unlimitedHeight={rest.schema?.metadata?.unlimitedHeight}
                    />
                  );
                case GeneralModel.JSONSchemaFormat.SEARCH:
                  return (
                    <SearchInput
                      id={rest.id}
                      testid={rest.id}
                      value={fixedProps.value}
                      disabled={props.disabled}
                      placeholder={getLabel(rest.displayNamePath)}
                      alternative={rest.schema?.metadata?.alt}
                      onChange={fixedProps.onChange}
                    />
                  );
                default:
                  logger.warn(`Unsupported data type ${format}`, { format, ...rest });
                  return null;
              }
            }}
          </EvaluatorWrapper>
        </ErrorBoundary>
      );
    },
    [allowFormula, apiQuery, getComponentRecord, getIds, getOptionMenu, originalInputList, logger, onDownloadFile, onGetFileRequest]
  );
};
