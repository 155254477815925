import { ELK, ElkExtendedEdge, ElkNode } from 'elkjs';
import { Elements, isEdge, isNode } from 'react-flow-renderer';

export const gapSize = { x: 180, y: 100, edgeMargin: 0 };

const DEFAULT_WIDTH = 75;
const DEFAULT_HEIGHT = 75;

export const getElementsWithPosition = async (elements: Elements, elkInstance: ELK): Promise<Elements> => {
  const nodeElements = elements.filter(isNode);
  const nodeEdges = elements.filter(isEdge);

  const nodes: ElkNode[] = nodeElements.map((node, index) => ({
    id: node.id,
    width: !index ? 0 : (node.__rf?.width ?? DEFAULT_WIDTH),
    height: !index ? 0 : (node.__rf?.height ?? DEFAULT_HEIGHT)
  }));
  const edges: ElkExtendedEdge[] = nodeEdges.map(edge => ({ id: edge.id, sources: [edge.source], targets: [edge.target] }));
  const newGraph = await elkInstance.layout({ id: 'root', children: nodes, edges });

  return [
    ...nodeElements.map((nodeElem, index) => {
      const node = newGraph?.children?.find(n => n.id === nodeElem.id);
      return { ...nodeElem, position: { y: (index % 2 ? node.x : node.x - 50) - node.height / 2, x: node.y - node.width / 2 } };
    }),
    ...nodeEdges.map(edgeElem => {
      const edge: any = newGraph?.edges?.find(e => e.id === edgeElem.id);
      const section = edge.sections?.[0];

      const path = `M ${section?.startPoint?.x} ${section?.startPoint?.y} L ${section?.endPoint?.x} ${section?.endPoint?.y}`;
      return { ...edgeElem, data: { ...edgeElem.data, path } };
    })
  ];
};
