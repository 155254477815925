import { PropsWithChildren, useMemo } from 'react';
import { getStyles } from './styles';

export type SidebarProps = PropsWithChildren<{
  open: boolean;
  wide?: boolean;
  verticalOffset?: number;
}>;

export const Sidebar = ({ open, wide, children, verticalOffset = 0 }: SidebarProps) => {
  const styles = useMemo(() => getStyles({ verticalOffset }), [verticalOffset]);
  return (
    <div css={[styles.pane, !!open && styles.paneOpen, !!open && !!wide && styles.wide]} data-testid="pane">
      {children}
    </div>
  );
};
