import { ReactNode } from 'react';
import { GeneralModel } from '@cyferd/client-engine';
import { SerializedStyles } from '@emotion/react';
import { OptionMenuProps } from '../OptionMenu';

export enum ArrowPosition {
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right'
}

export interface IPopoverTrigger {
  label?: string;
  value: string | ReactNode;
  testId?: string;
  children: React.ReactNode;
  containerCss?: SerializedStyles;
  contentCss?: SerializedStyles;
  optionList?: OptionMenuProps['optionList'];
  avoidOpenOnClick?: boolean;
  color?: GeneralModel.Color.ThemeColor;
  timeout?: number;
}

export interface IDialog {
  value: string | ReactNode;
  onClose: (e?: React.MouseEvent<HTMLDialogElement> | MouseEvent) => void;
  coords?: { top: number; left: number; arrowPosition: ArrowPosition };
  dialogRef: (node: any) => void;
  label: string;
  testId?: string;
  optionList?: OptionMenuProps['optionList'];
  color?: GeneralModel.Color.ThemeColor;
  timeout?: number;
}

export interface IPopover extends IDialog {
  open: boolean;
}
