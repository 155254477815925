import { useMemo } from 'react';

import { GeneralModel, Translate, ViewModel } from '@cyferd/client-engine';
import * as RadixCheckbox from '@radix-ui/react-checkbox';

import { Icon } from '../Icon/Icon';
import { InputDescription } from '../InputDescription';
import { COLOR, FOREGROUND_COLOR, SECONDARY_COLOR } from '@constants';
import { styles } from './styles';
import { OptionMenu, OptionMenuProps } from '../OptionMenu';
import { InfoForLabel } from '../InfoForLabel';
import { CTAType } from '../CTA';
import { InputErrorMesssage } from '../InputErrorMessage';

export interface CheckboxProps {
  value?: boolean;
  labelPosition?: 'right' | 'left';
  label: string;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  name?: string;
  color?: GeneralModel.Color.ThemeColor;
  onChange?: (value: boolean) => void;
  description?: string;
  errorMessage?: string;
  testid?: string;
  required?: boolean;
  optionList?: OptionMenuProps['optionList'];
  info?: string;
}

export const Checkbox = ({
  labelPosition = 'right',
  disabled,
  label,
  name,
  value,
  id,
  onChange,
  color,
  autoFocus,
  testid = 'checkbox',
  description,
  required,
  errorMessage,
  optionList,
  info
}: CheckboxProps) => {
  const isIndeterminate = value === undefined || value === null;
  const mainColor: GeneralModel.Color.ThemeColor = useMemo(() => (COLOR[color] ? color : 'BRAND_1'), [color]);
  const stateColor = useMemo(() => (!disabled ? COLOR[mainColor] : SECONDARY_COLOR[mainColor]), [disabled, mainColor]);

  const onInputChange = () => {
    onChange?.(!value);
  };

  const labelElement = useMemo(
    () =>
      !!label || required || !!description ? (
        <div data-testid={`${testid}-label`} css={[styles.label, labelPosition === 'left' && styles.labelLeft]}>
          <div data-testid={`${testid}-title`} css={[styles.text, styles.title]} data-selector="checkbox-label">
            <Translate>{label}</Translate> <InfoForLabel label={label} value={info} testId={testid} />
            {required ? '*' : ''}
          </div>
          <InputDescription description={description} showPadding={false} />
        </div>
      ) : null,
    [description, info, label, labelPosition, testid, required]
  );

  return (
    <div>
      <div css={styles.container} data-selector="checkbox-container">
        <label css={[styles.checkboxElement, disabled && styles.checkboxElementDisabled]} data-testid={`${testid}-container`} htmlFor={id}>
          {labelPosition === 'left' && labelElement}
          <RadixCheckbox.Root
            css={[styles.checkbox, !!value && styles.checked]}
            style={{ backgroundColor: !!value && stateColor, borderColor: disabled ? COLOR.TRANSPARENT : stateColor }}
            checked={value}
            autoFocus={autoFocus}
            onCheckedChange={onInputChange}
            disabled={disabled}
            aria-errormessage={errorMessage ? `${id}-error` : undefined}
            aria-invalid={errorMessage ? 'true' : 'false'}
            name={name}
            id={id}
            data-testid={testid}
            data-focusable={!disabled}
          >
            {isIndeterminate && (
              <div data-testid={`${testid}-indeterminate`} css={styles.iconIndeterminate}>
                <Icon name="remove" fill={disabled ? COLOR.NEUTRAL_1_5 : stateColor} />
              </div>
            )}
            <RadixCheckbox.Indicator css={styles.icon}>
              {value === true && <Icon name="check" fill={disabled ? COLOR.NEUTRAL_1_5 : FOREGROUND_COLOR[mainColor]} />}
            </RadixCheckbox.Indicator>
          </RadixCheckbox.Root>
          {labelPosition === 'right' && labelElement}
        </label>
        <OptionMenu defaultBtnType={CTAType.LINK} optionList={optionList} defaultBtnSize={ViewModel.CTASize.MEDIUM} />
      </div>
      <InputErrorMesssage id={id} message={errorMessage} testid={testid} showLine={true} />
    </div>
  );
};

Checkbox.displayName = 'Checkbox';
