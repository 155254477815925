import React, { forwardRef, MutableRefObject, ReactNode, useRef } from 'react';
import { EMPTY } from 'rxjs';

import { COLOR } from '@constants';
import { styles } from './styles';
import { Icon, IconKeys } from '../Icon';
import { Spinner } from '../Spinner';
import { getFileIconProps } from '@utils';

export enum DropzoneFileStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading'
}

export type DropzoneFile = { id: string; metadata?: File; status: DropzoneFileStatus; title?: string; mimeType?: string; uploadedAt?: number };

export interface DropzoneProps {
  value: DropzoneFile[];
  onChange: (files: File[]) => void;
  disabled?: boolean;
  testid?: string;
  actionMenu?: ReactNode;
  multiple?: boolean;
  onPreview?: () => void;
  allowedFileTypes?: string[];
  readOnly?: boolean;
  disableDragAndDrop?: boolean;
}

export const Dropzone = forwardRef<HTMLInputElement, DropzoneProps>(
  ({ onChange, value, actionMenu, multiple, testid, disabled, onPreview, allowedFileTypes, readOnly, disableDragAndDrop }: DropzoneProps, ref) => {
    const internalTestid = testid ? `${testid}-dropzone` : 'dropzone';
    const inputFile = useRef<HTMLInputElement>();

    const onInputClick = () => {
      ((ref as MutableRefObject<HTMLInputElement>)?.current || inputFile?.current)?.click();
      return EMPTY;
    };
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange([...event.target.files]);

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onChange(Array.from(event.dataTransfer.files));
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const isContainerPressable = !disabled && (!value?.length || !!value?.some(f => f.status === DropzoneFileStatus.ERROR));

    return (
      <>
        <input
          ref={ref || inputFile}
          multiple={multiple}
          autoComplete="new-password"
          type="file"
          css={styles.input}
          onChange={onInputChange}
          data-testid={`${internalTestid}-input`}
          disabled={disabled}
          accept={allowedFileTypes?.join(',')}
          value=""
        />
        <div
          css={[styles.dpContainer(readOnly), (isContainerPressable || !!value?.some(f => !!f?.uploadedAt)) && styles.pressableContainer]}
          draggable={true}
          onDrop={disableDragAndDrop ? undefined : onDrop}
          onDragOver={disableDragAndDrop ? undefined : onDragOver}
          data-testid={`${internalTestid}-area`}
          onClick={isContainerPressable ? onInputClick : undefined}
        >
          {!value?.length && !readOnly && (
            <div css={styles.dpSubtitleContainer}>
              <Icon outlined={true} name="cloud_upload" size="16px" fill={disabled ? COLOR.NEUTRAL_1_5 : COLOR.NEUTRAL_2} title="Upload" />
              <span css={[styles.dpSubtitleText, disabled && /* istanbul ignore next */ styles.dpSubtitleTextDisabled]}>
                Drag and drop or <span>select a file</span>
              </span>
            </div>
          )}
          {!!value?.length &&
            value
              .filter(item => item?.id)
              .map((item, index) => {
                const fileIcon = getFileIconProps(item.mimeType);
                return (
                  <div
                    key={`${item.id}-${index}`}
                    css={[styles.fileContainer, index !== value.length - 1 && styles.fileContainerSeparator]}
                    onClick={item.status === DropzoneFileStatus.SUCCESS ? onPreview : undefined}
                  >
                    <div css={styles.fileInfoContainer}>
                      {item.status === DropzoneFileStatus.LOADING && (
                        <div css={styles.fileIndicatorContainer}>
                          {!!fileIcon && <Icon name={fileIcon.name as IconKeys} size="16px" title="File" fill={fileIcon.color} />}
                          <div css={styles.fileSpinnerContainer}>
                            <Spinner size="22px" />
                          </div>
                        </div>
                      )}
                      {item.status === DropzoneFileStatus.ERROR && (
                        <div css={styles.fileIndicatorContainer}>
                          <Icon outlined={true} name="cancel" size="16px" fill={COLOR.HC_5} title="File error" />
                        </div>
                      )}
                      {!!fileIcon && item.status === DropzoneFileStatus.SUCCESS && (
                        <div css={styles.fileIndicatorContainer}>
                          <Icon name={fileIcon.name as IconKeys} size="16px" title="File" fill={fileIcon.color} />
                        </div>
                      )}
                      <div>{!!item.title && <p css={[styles.fileTitle, disabled && !readOnly && styles.disabledItemTitle]}>{item.title}</p>}</div>
                    </div>
                    <div css={styles.fileActionContainer}>{!index && actionMenu}</div>
                  </div>
                );
              })}
        </div>
      </>
    );
  }
);

Dropzone.displayName = 'Dropzone';
