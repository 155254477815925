import { ComponentProps, memo, useCallback, useContext, useId, useMemo } from 'react';

import {
  CollectionModel,
  GeneralModel,
  getParsedActionChildren,
  ParsedListHeadItem,
  swallowError,
  useRecordActionsParser,
  ViewModel
} from '@cyferd/client-engine';

import { CyWrapperContext } from '../CyWrapper';
import { styles } from './styles';
import { COLOR, FONT_SIZE } from '@constants';
import { Icon, IconKeys } from '@components/elements/Icon';
import { ToolTip } from '@components/elements/Tooltip';
import { InfoForLabel } from '@components/elements/InfoForLabel';
import { Table } from '@components/elements/Table';
import { ReadonlyFormat } from '@components/elements/ReadonlyFormat';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTAType } from '@components/elements/CTA';
import { tableCellNeedsColor } from '@components/elements/Table/TableCell';

export interface SmartTableProps {
  isLoading: boolean;
  testid?: string;
  orderHidden?: boolean;
  value: ViewModel.CyListProps['value'];
  actionListChildren: ViewModel.CyListProps['actionListChildren'];
  rowColor: ViewModel.CyListProps['rowColor$'];
  recordActions?: CollectionModel.Collection['recordActions'];
  onFetch: ViewModel.CyListProps['onFetch'];
  onClickItem: ViewModel.CyListProps['onClickItem'];
}

export const SmartTable = memo(
  ({ isLoading, testid, value, actionListChildren, onFetch, onClickItem, orderHidden, recordActions, rowColor }: SmartTableProps) => {
    const { useParsers } = useContext(CyWrapperContext);
    const { parseList } = useParsers({ query: value?.query, rowColor });
    const dropdownContainerId = useId();
    const parseRecordActions = useRecordActionsParser(recordActions);

    const { head, items, definitionMap } = useMemo(
      () => parseList({ entity: value?.query, list: value?.list, rowColor }),
      [parseList, rowColor, value?.list, value?.query]
    );

    const hasActions = useMemo(() => [actionListChildren?.length, recordActions?.length].some(Boolean), [actionListChildren?.length, recordActions?.length]);

    const onColumnClick = useCallback(
      (col: ParsedListHeadItem, sorting: boolean) => {
        if (!onFetch || !value?.query?.cursor) return;
        onFetch({
          ...value.query.cursor,
          options: { ...value.query.cursor?.options, orderBy: col.definitionId, descending: sorting ? !value.query.cursor?.options?.descending : false }
        })
          .pipe(swallowError())
          .subscribe();
      },
      [onFetch, value?.query?.cursor]
    );

    const tableHead = useMemo(
      () =>
        [
          ...head.map(col => {
            const isSorting: boolean = value.query?.cursor?.options?.orderBy === col.definitionId;
            const iconName: IconKeys = !value.query?.cursor?.options?.descending ? 'arrow_upward' : 'arrow_downward';
            const info = definitionMap[col.definitionId]?.property?.info;
            const shouldShowSort = !orderHidden && !!GeneralModel.formatIsSortableMap[definitionMap[col.definitionId]?.property?.format];
            return {
              id: `${col.definitionId}`,
              item: (
                <button
                  data-testid="column-head"
                  onClick={!!shouldShowSort ? () => onColumnClick(col, isSorting) : undefined}
                  disabled={isLoading}
                  css={[styles.columnHeaderButtonStyles, !!shouldShowSort && styles.clickable]}
                >
                  {!!shouldShowSort && (
                    <Icon
                      testid="table-header-sorting-icon-container"
                      name={isSorting ? iconName : 'swap_vert'}
                      fill={isSorting ? COLOR.HC_15 : COLOR.NEUTRAL_2}
                      size={FONT_SIZE.S}
                    />
                  )}
                  <ToolTip text={String(col.value)}>
                    <div css={styles.titleContainerStyles}>
                      <span style={{ color: isSorting ? COLOR.HC_15 : COLOR.NEUTRAL_1 }}>{String(col.value)}</span>
                      {!!info && <InfoForLabel label={col.value} value={info} />}
                    </div>
                  </ToolTip>
                </button>
              )
            };
          }),
          hasActions && {
            id: 'action-list-header-col',
            item: (
              <button css={styles.columnHeaderButtonStyles}>
                <div css={[styles.titleContainerStyles, styles.actionColumnTitle]}>A</div>
              </button>
            )
          }
        ].filter(Boolean) as ComponentProps<typeof Table>['head'],
      [head, hasActions, value.query?.cursor?.options?.orderBy, value.query?.cursor?.options?.descending, definitionMap, orderHidden, isLoading, onColumnClick]
    );

    const tableBody = useMemo(
      () =>
        items.map((item, itemIndex) => {
          const completeActions = [
            ...parseRecordActions({ item: item.fullItem, index: itemIndex }),
            ...getParsedActionChildren(actionListChildren, { item: item.fullItem, index: itemIndex })
          ];
          return [
            ...item.list.map(col => {
              const definition = definitionMap[col.definitionId];
              const isBasicArray = [
                GeneralModel.JSONSchemaFormat.ARRAY,
                GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
                GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
                GeneralModel.JSONSchemaFormat.FILE_LIST
              ].includes(definition?.rawProperty?.format);

              return {
                id: `${itemIndex}-${col.definitionId}`,
                color: !isBasicArray && tableCellNeedsColor(col.value) ? col.color : undefined,
                rowColor: item.color,
                rowBgColor: item.rowColor,
                format: definition.property?.format,
                onClickItem: typeof onClickItem === 'function' ? event => onClickItem(item.fullItem, { metaKey: event?.metaKey, index: itemIndex }) : undefined,
                item: (
                  <ReadonlyFormat
                    item={{ ...col, color: col.color }}
                    definition={definition}
                    fullRecord={item.fullItem}
                    collectionId={item.fullItem?.collectionId}
                    recordId={item.fullItem?.id}
                    disabled={!!isLoading}
                  />
                )
              };
            }),
            !!hasActions && {
              id: `${itemIndex}-action-list-body-col`,
              item: (
                <div css={styles.actionsContainer}>
                  <PreventClickPropagation>
                    <OptionMenu
                      defaultBtnType={CTAType.LINK}
                      containerId={dropdownContainerId}
                      optionList={completeActions.map((actionItem, actionItemIndex) => ({
                        label: actionItem.label,
                        image: actionItem.icon,
                        disabled: isLoading || !!actionItem.disabled,
                        onClick: actionItem.onClick && (event => actionItem.onClick(item.fullItem, { index: itemIndex, metaKey: event?.metaKey })),
                        testid: `${itemIndex}-action-list-cta-${actionItemIndex}`,
                        color: actionItem.color,
                        tooltip: actionItem.helperText,
                        status: (actionItem as any).status,
                        type: actionItem.type || CTAType.ACTION,
                        size: ViewModel.CTASize.SMALL,
                        important: actionItem.important
                      }))}
                    />
                  </PreventClickPropagation>
                </div>
              )
            }
          ].filter(Boolean);
        }) as ComponentProps<typeof Table>['body'],
      [actionListChildren, definitionMap, dropdownContainerId, hasActions, isLoading, items, onClickItem, parseRecordActions]
    );

    return (
      <div css={styles.tableContainer}>
        <Table head={tableHead} body={tableBody} lastColumnSticky={!!hasActions} testid={`${testid}-table`} />
        <div id={dropdownContainerId} />
      </div>
    );
  }
);

SmartTable.displayName = 'SmartTable';
