import { useMemo } from 'react';

import { BaseFieldProps, GeneralModel } from '@cyferd/client-engine';

import { OptionMenuProps } from '../OptionMenu';
import { styles } from './styles';
import { InputWrapper } from '../InputWrapper';
import { Checkbox } from '../Checkbox';

export interface MultiOptionListProps extends Pick<BaseFieldProps, 'id' | 'disabled'> {
  optionList: GeneralModel.JSONSchemaMetadata['optionList'];
  value: (string | number)[];
  label?: string;
  description?: string;
  errorMessage?: string;
  required?: boolean;
  menuOptionList?: OptionMenuProps['optionList'];
  onChange: (value: (string | number)[]) => void;
  info?: string;
  disabledType?: GeneralModel.DisabledType;
}

export const MultiOptionList = ({
  id = 'multi-option-list',
  disabled,
  value,
  optionList = [],
  menuOptionList,
  label,
  description,
  errorMessage,
  required,
  onChange,
  info,
  disabledType
}: MultiOptionListProps) => {
  const safeValue = useMemo(() => (Array.isArray(value) ? value : []), [value]);

  const onInternalChange = (v: string | number) => {
    onChange(safeValue.includes(v) ? safeValue.filter(s => v !== s) : [...safeValue, v]);
  };

  return (
    <InputWrapper
      label={label}
      disabled={!!disabled}
      disabledType={disabledType}
      description={description}
      errorMessage={errorMessage}
      optionList={menuOptionList}
      required={required}
      unlimitedHeight={true}
      info={info}
    >
      <div css={styles.container}>
        <div css={styles.content}>
          <div id={id} data-testid={id} css={[styles.optionList, disabled && styles.optionListDisabled]}>
            {(optionList || /* istanbul ignore next */ []).map((option, i) => (
              <div key={`${option.value}-${i}`}>
                <Checkbox
                  onChange={() => onInternalChange(option.value)}
                  value={safeValue.includes(option.value)}
                  id={`${id}-${option.value}`}
                  testid={`${id}-${option.value}`}
                  name={option.label}
                  label={option.label !== undefined ? option.label : String(option.value)}
                  disabled={disabled}
                  description={option.description}
                  color={option.color as GeneralModel.Color.ThemeColor}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </InputWrapper>
  );
};
