/* istanbul ignore file */
import { useCallback, useEffect, useRef, useState } from 'react';

import { useGetElementSize } from '@utils';

import { ArrowPosition, IDialog } from './types';

export const useCalculateCoords = ({ left, top }: { left?: number; top?: number }) => {
  const [coords, setCoords] = useState<IDialog['coords']>(undefined);
  const { ref, width, height } = useGetElementSize();
  const widthRef = useRef<number>();
  const heightRef = useRef<number>();

  useEffect(() => {
    if (width > 0 && height > 0) {
      if (widthRef.current === undefined && heightRef.current === undefined) {
        widthRef.current = width;
        heightRef.current = height;
      }
    }
  }, [width, height]);

  const calculateCoords = useCallback((widthRef: number, heightRef: number, internalLeft: number, internaltop: number) => {
    const { clientWidth, clientHeight } = document.documentElement;

    // Calculate available space in both dimensions
    const availableSpaceOnRight = clientWidth - internalLeft;
    const availableSpaceOnBottom = clientHeight - internaltop;
    const availableSpaceOnTop = internaltop;
    const availableSpaceOnLeft = internalLeft;

    // Variables to track available space in different directions
    const fitsOnRight = availableSpaceOnRight >= widthRef;
    const fitsOnLeft = availableSpaceOnLeft >= widthRef;
    const fitsOnBottom = availableSpaceOnBottom >= heightRef;
    const fitsOnTop = availableSpaceOnTop >= heightRef;

    // Calculate arrowPosition based on available space
    if (fitsOnRight && fitsOnTop) {
      return { top: internaltop - heightRef - 35, left: internalLeft - 30, arrowPosition: ArrowPosition.BOTTOM_LEFT };
    }
    if (fitsOnLeft && fitsOnTop) {
      return { top: internaltop - heightRef + 20, left: internalLeft - widthRef - 30, arrowPosition: ArrowPosition.BOTTOM_RIGHT };
    }
    if (fitsOnRight && fitsOnBottom) {
      return { top: internaltop + 25, left: internalLeft - 35, arrowPosition: ArrowPosition.TOP_LEFT };
    }
    if (fitsOnLeft && fitsOnBottom) {
      return { top: internaltop - 15, left: internalLeft - widthRef - 28, arrowPosition: ArrowPosition.TOP_RIGHT };
    }
    if (fitsOnTop) {
      return { top: internaltop - heightRef - 20, left: internalLeft, arrowPosition: ArrowPosition.BOTTOM_LEFT };
    }
    if (fitsOnBottom) {
      return { top: internaltop + 20, left: internalLeft, arrowPosition: ArrowPosition.TOP_LEFT };
    }
    const arrowPosition = availableSpaceOnRight >= availableSpaceOnLeft ? ArrowPosition.TOP_LEFT : ArrowPosition.BOTTOM_LEFT;
    return { top: internaltop - heightRef, left: internalLeft, arrowPosition };
  }, []);

  useEffect(() => {
    if (widthRef.current > 0 && heightRef.current > 0 && left !== undefined && top !== undefined) {
      const { clientWidth, clientHeight } = document.documentElement;
      const newCoords = calculateCoords(widthRef.current, heightRef.current, left, top);
      const clampedCoords = {
        top: Math.min(Math.max(newCoords.top, 1), clientHeight - heightRef.current),
        left: Math.min(Math.max(newCoords.left, 1), clientWidth - widthRef.current),
        arrowPosition: newCoords.arrowPosition
      };
      setCoords(clampedCoords);
    }
  }, [width, height, calculateCoords, left, top]);

  return { coords, dialogRef: ref };
};
