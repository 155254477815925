import { GeneralModel, Translate, useTranslate } from '@cyferd/client-engine';

import { COLOR, FONT_SIZE } from '@constants';
import { CTA, CTAType } from '../CTA';
import { Icon } from '../Icon';
import { IconImage } from '../Icon/renderIcon';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { styles } from './styles';
import { SelectDropdownType } from '../SelectDropdown/types';

export interface DropdownOptionProps {
  type?: SelectDropdownType;
  testid?: string;
  image?: string;
  title?: string;
  description?: string;
  color?: GeneralModel.Color.ThemeColor;
  value?: any;
  truncate?: boolean;
  disableHover?: boolean;
  disabled?: boolean;
  highlight?: boolean;
  onClick?: (value: any) => void;
  onClose?: () => void;
}

export const DropdownOption = ({
  type = SelectDropdownType.REGULAR,
  testid = 'dropdown-option',
  image,
  title,
  disableHover,
  description,
  value,
  color,
  onClick,
  disabled,
  highlight,
  truncate,
  onClose
}: DropdownOptionProps) => {
  const { translate } = useTranslate();
  return (
    <CTA type={CTAType.SEEMLESS} width="full" onClick={typeof onClick === 'function' ? () => onClick(value) : undefined} testid={testid} disabled={disabled}>
      <div
        data-selector="dropdown-option"
        css={[
          styles.container,
          !!COLOR[color] && styles.containerLeftRoundCorners,
          highlight && styles.containerHighlighted,
          (disabled || disableHover) && styles.containerDisabled
        ]}
      >
        <div css={styles.infoContainer}>
          <div css={styles.colorBar} style={{ backgroundColor: COLOR[color] }}></div>
          {!!image && (
            <div css={styles.imageContainer}>
              <IconImage
                icon={image}
                title={translate(title)}
                iconProps={{
                  size: '20px',
                  fill: highlight ? COLOR.BASE_FOREGROUND : COLOR.NEUTRAL_1,
                  css: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
                }}
                imageProps={{ css: { width: 20, height: 20, objectFit: 'cover', borderRadius: '100%' } }}
              />
            </div>
          )}
          <div css={styles.textContainer}>
            {!!title && (
              <p css={[styles.title, type !== SelectDropdownType.PAGINATION && highlight && styles.highlightedTitle, truncate && styles.truncate]}>
                <Translate>{title}</Translate>
              </p>
            )}
            {!!description && (
              <p css={[styles.description, highlight && styles.highlightedDescription, truncate && styles.truncate]}>
                <Translate>{description}</Translate>
              </p>
            )}
          </div>
        </div>
        {!!onClose && !disabled && (
          <PreventClickPropagation>
            <div onClick={onClose} data-testid={`${testid}-close`} css={styles.closeContainer}>
              <Icon name="close" fill={COLOR.NEUTRAL_2} size={FONT_SIZE.XM} />
            </div>
          </PreventClickPropagation>
        )}
      </div>
    </CTA>
  );
};
