import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useId, useState } from 'react';

import { getElementMenuPosition } from '@utils/getElementMenuPosition';
import { useDropdownDimensions, useOutsideClick } from '@utils';
import { usePopover } from '@components/providers/UIprovider';
import { styles } from './styles';
import { DropdownMenuType } from './types';

export interface DropdownMenuProps {
  renderTrigger: (props?: {
    isOpen: boolean;
    onClick: () => void;
    onContextMenu: (e?: React.MouseEvent) => void;
    tabIndex: number;
    testid: string;
    ref;
  }) => ReactElement;
  styling?: any;
  renderMenuWrapper?: (props?: {
    style: { [key: string]: any };
    css: any;
    onClick: () => void;
    children: React.ReactNode;
    ref: (node: any) => void;
  }) => ReactElement;
  testid?: string;
  horizontal?: number;
  vertical?: number;
  horizontalResponsive?: boolean;
  type?: DropdownMenuType;
}

export const DropdownMenu = ({
  renderTrigger,
  renderMenuWrapper = ({ css, onClick, children, style, ref }) => {
    return (
      <div css={styles.menuContainer}>
        <div style={style} css={css} onClick={onClick} ref={ref}>
          {children}
        </div>
      </div>
    );
  },
  children,
  styling,
  horizontal = 0,
  vertical = 0,
  horizontalResponsive = true,
  type = DropdownMenuType.OPTION_MENU,
  testid = 'dropdown-menu'
}: PropsWithChildren<DropdownMenuProps>) => {
  const isOptionMenuType = type === DropdownMenuType.OPTION_MENU;
  const isContextMenuType = type === DropdownMenuType.CONTEXT_MENU;
  const dropdownId = useId();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [rightClickCoords, setRightClickCoords] = useState({ x: undefined, y: undefined });
  const onClickOutside = useCallback(() => setIsOpen(false), []);
  const mainRef: React.LegacyRef<HTMLDivElement> = useOutsideClick(onClickOutside);
  const {
    optionMenu: { currentOptionMenuId, openOptionMenu, closeOptionMenu }
  } = usePopover();

  useEffect(() => {
    if (isOpen) {
      openOptionMenu(dropdownId);
    } else {
      closeOptionMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (currentOptionMenuId && currentOptionMenuId !== dropdownId) {
      setIsOpen(false);
    }
  }, [currentOptionMenuId, dropdownId]);

  const { dropdownRef, triggerDimensions, menuDimensions, clientDimensions } = useDropdownDimensions(mainRef);

  const onContextMenu = useCallback(e => {
    e.preventDefault();
    setRightClickCoords({ x: e.clientX, y: e.clientY });
    setIsOpen(true);
  }, []);

  return (
    <div data-testid={`${testid}-container`} css={styling} data-selector="dropdown-menu-container">
      {renderTrigger({
        onClick: isOptionMenuType ? () => setIsOpen(prev => !prev) : undefined,
        onContextMenu: isContextMenuType ? onContextMenu : undefined,
        tabIndex: 0,
        isOpen,
        testid: `${testid}-trigger`,
        ref: mainRef
      })}
      {!!isOpen &&
        (() => {
          const menuElementPosition = getElementMenuPosition({
            triggerDimensions: isOptionMenuType ? triggerDimensions : { x: rightClickCoords.x, y: rightClickCoords.y },
            menuDimensions,
            clientDimensions,
            verticalPadding: vertical,
            horizontalPadding: horizontal,
            type
          });
          return renderMenuWrapper({
            css: styles.childContainer,
            onClick: () => setIsOpen(false),
            children,
            ref: dropdownRef,
            style: {
              top: menuElementPosition.style.top,
              left: horizontalResponsive ? menuElementPosition.style.left : triggerDimensions.x,
              opacity: Number(!!(menuDimensions.height || menuDimensions.width)),
              transition: 'opacity 0.3s'
            }
          });
        })()}
    </div>
  );
};
