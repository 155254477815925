import { GeneralModel, Translate, ViewModel } from '@cyferd/client-engine';
import { styles } from './styles';
import { useMemo } from 'react';
import { OptionMenu, OptionMenuProps } from '@components/elements/OptionMenu';
import { CTA, CTAType } from '../../CTA';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { ToolTip } from '@components/elements/Tooltip';
import { FONT_SIZE } from '@constants';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { useSelectNonDisabledTab } from '../useSelectFirstNonDisabledTab';

export enum TabListType {
  LAYOUT = 'layout',
  FORM = 'form'
}

export interface TabListProps {
  tabList: ViewModel.CyLayoutProps['tabList'];
  activeTab: string;
  tabColor?: GeneralModel.Color.ThemeColor;
  optionMenuProps?: OptionMenuProps;
  onChangeTab: ViewModel.CyLayoutProps['onChangeTab'];
  startsCollapsed?: boolean;
  type?: TabListType;
}

export const TabList = ({ tabList, activeTab, tabColor, optionMenuProps, onChangeTab, type = TabListType.LAYOUT }: TabListProps) => {
  const list = useMemo(() => (tabList || []).filter(tab => !tab.hidden), [tabList]);
  const isFormType = type === TabListType.FORM;
  useSelectNonDisabledTab({ activeTab, tabList: list, onChangeTab });

  return (
    list?.length > 0 && (
      <div css={styles.container}>
        <div css={[styles.tabsContainer, !isFormType && styles.tabsBorder, isFormType && styles.formTabsContainer]}>
          {list
            .filter(tab => !tab.hidden)
            .map((tab, index) => {
              const isActive = activeTab === tab.title;
              return (
                <div id={tab.title} key={index} role="tab" css={[styles.btnContainer, isActive && isFormType && styles.activeBtnContainer]}>
                  <ToolTip text={tab.displayName || tab.title}>
                    <CTA
                      type={CTAType.SEEMLESS}
                      disabled={!!tab.disabled}
                      onClick={() => onChangeTab(tab.title)}
                      testid={`tab-${index + 1}`}
                      hideLoading={true}
                    >
                      <div css={[styles.tabContainer, !tab.disabled && styles.tabContainerHover]}>
                        {!!isFormType && (
                          <div
                            css={[
                              styles.formActiveBar,
                              !!isActive && styles.activeBarColor(tabColor),
                              !!tab.disabled && !!isActive && styles.disabledActiveBar
                            ]}
                          />
                        )}
                        <span
                          css={[
                            styles.tab,
                            !!isActive && styles.activeTab(tabColor),
                            !!tab.disabled && styles.disabledTab,
                            isFormType && styles.formTab,
                            isFormType && !!isActive && styles.formActiveTab,
                            isFormType && !!tab.disabled && styles.formDisabledTab
                          ]}
                        >
                          {!!tab.icon && (
                            <IconImage
                              title=""
                              icon={tab.icon}
                              imageProps={{ size: FONT_SIZE.XXM }}
                              iconProps={{ size: FONT_SIZE.XXM, css: { display: 'flex' } }}
                            />
                          )}
                          <span data-selector="tab-display-name">
                            <Translate>{tab.displayName || tab.title}</Translate>
                          </span>
                        </span>
                        {!isFormType && (
                          <div
                            css={[styles.activeBar, !!isActive && styles.activeBarColor(tabColor), !!tab.disabled && !!isActive && styles.disabledActiveBar]}
                          />
                        )}
                      </div>
                    </CTA>
                  </ToolTip>
                </div>
              );
            })}
          {isFormType && <div css={[styles.borderBottom]} />}
        </div>
        {!!optionMenuProps?.optionList?.length && (
          <div css={styles.actions}>
            <PreventClickPropagation>
              <OptionMenu defaultBtnType={ViewModel.CTAType.LINK} {...optionMenuProps} />
            </PreventClickPropagation>
          </div>
        )}
      </div>
    )
  );
};
