import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP, HC_COLOR, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';

const getColors = ({ safeDisabled, color, active }: { safeDisabled: boolean; color: GeneralModel.Color.ThemeColor; active: boolean }) => {
  if (safeDisabled) return { background: COLOR.NEUTRAL_3_5, text: COLOR.NEUTRAL_1_5 };
  return active ? { background: COLOR[color], text: FOREGROUND_COLOR[color] } : { background: COLOR.BASE_BACKGROUND_ALT_1, text: HC_COLOR[color] };
};

export const CHIP_HEIGHT = 22;

export const styles = {
  chipList: css`
    display: flex;
    gap: ${GAP.S} ${GAP.XS};
    flex-wrap: wrap;
    align-items: center;
  `,
  chipListLimited: css`
    max-height: ${CHIP_HEIGHT * 2.6}px;
    overflow: auto;
  `,
  collapsed: css`
    max-height: ${CHIP_HEIGHT + 1}px;
    overflow: auto;
  `,
  disabled: css`
    cursor: not-allowed;
  `,
  container: ({
    color,
    active,
    compact,
    safeDisabled,
    disabled
  }: {
    color: GeneralModel.Color.ThemeColor;
    active: boolean;
    compact: boolean;
    safeDisabled: boolean;
    disabled: boolean;
  }) => css`
    button {
      background-color: ${getColors({ safeDisabled, color, active }).background};
      border: 1px ${COLOR[color]} solid;
      border-radius: ${RADIUS.L};
      padding: 0 ${GAP.S};
      display: flex;
      align-items: center;
      gap: ${GAP.XS};
      flex-direction: row-reverse;
      transition: all ${TRANSITION_SPEED};
      ${!compact && `height: ${CHIP_HEIGHT}px;`};

      :hover {
        ${!disabled && `background-color: ${SECONDARY_COLOR[color]};`}
      }
    }
  `,
  content: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XS};
    max-width: 235px;
  `,
  text: ({ color, active, safeDisabled }: { color: GeneralModel.Color.ThemeColor; active: boolean; safeDisabled: boolean }) => css`
    font-size: ${FONT_SIZE.XS};
    color: ${getColors({ color, active, safeDisabled }).text};
    font-weight: ${active ? FONT_WEIGHT.BOLD : FONT_WEIGHT.NORMAL};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
};
