import { BaseFieldProps, GeneralModel, SchemaFormBaseProps } from '@cyferd/client-engine';
import { useCallback } from 'react';
import { FormulaInputRow, shouldAllowFormula } from '@components/elements/Evaluator/resources';
import { MenuOption } from '@components/elements/OptionMenu';
import { getLabel } from '@utils/getLabel';
import { EvaluatorWrapper } from '@components/elements/Evaluator/EvaluatorWrapper/EvaluatorWrapper';
import { Input, InputTypes } from '@components/elements/Input/Input';
import { VIEW_COMPONENT_FORMAT } from '@constants';
import { ViewComponentInput } from '@components/elements/ViewComponentInput';

export const useRenderText = ({
  getIds,
  getOptionMenu,
  getErrorMessage,
  allowFormula,
  inputList
}: {
  getIds: Function;
  allowFormula: boolean;
  inputList: FormulaInputRow[];
  getOptionMenu: (event: BaseFieldProps) => MenuOption[];
  getErrorMessage: (hasError: boolean, error: string) => string;
}): SchemaFormBaseProps['componentRecord']['renderText'] =>
  useCallback(
    props => {
      const {
        id,
        value,
        displayNamePath,
        onChange,
        format,
        maxLength,
        minLength,
        color,
        description,
        disabled,
        disabledType,
        required,
        error,
        hasError,
        schema,
        path
      } = props;

      const actualInputList = [...(schema.metadata?.inputList || []), ...(inputList || [])];

      if (format === VIEW_COMPONENT_FORMAT) return <ViewComponentInput {...props} inputList={actualInputList} />;
      return (
        <EvaluatorWrapper
          label={getLabel(displayNamePath)}
          allowFormula={shouldAllowFormula(allowFormula, schema.metadata?.allowFormula)}
          value={value}
          disabled={disabled}
          onChange={onChange}
          inputList={actualInputList}
          format={format}
          openModalOnFocus={schema?.metadata?.openModalOnFocus}
          description={description}
          required={required}
          info={schema?.info}
          color={color}
          disabledType={disabledType || schema?.metadata?.disabledType}
          optionList={getOptionMenu(props)}
        >
          {fixedProps => (
            <Input
              {...getIds(id)}
              type={
                ([...GeneralModel.typeToFormatMap.string].includes(format as GeneralModel.JSONSchemaFormat)
                  ? format
                  : GeneralModel.JSONSchemaFormat.TEXT) as InputTypes
              }
              name={getLabel(displayNamePath)}
              label={getLabel(displayNamePath)}
              maxLength={maxLength}
              minLength={minLength}
              disabled={disabled}
              description={description}
              value={fixedProps.value ? String(fixedProps.value) : ''}
              onChange={fixedProps.onChange}
              required={required}
              color={color}
              allowFileInput={schema.metadata?.allowFileInput}
              optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
              errorMessage={getErrorMessage(hasError, error)}
              suggestionList={schema.metadata?.suggestionList}
              metadata={schema.metadata}
              showClear={true}
              onPaste={fixedProps.onPaste}
              info={schema.info}
              unlimitedHeight={schema.metadata?.unlimitedHeight}
              disabledType={disabledType || schema.metadata?.disabledType}
              path={path}
            />
          )}
        </EvaluatorWrapper>
      );
    },
    [allowFormula, getErrorMessage, getIds, getOptionMenu, inputList]
  );
